import React, { useState } from "react"
import Button from "react-bootstrap/Button"

import Icon from "../components/Icon"
import Layout from "../components/Layout"
import Heading from "../components/Layout/Heading"
import SubHeading from "../components/Layout/SubHeading"
import SEO from "../components/SEO"
import ImageGallery from "../components/ImageGallery"
import StockCarsForm from "../components/forms/StockCars"

import "./vehicle.scss"

const fileBucket = process.env.FILE_BUCKET

const SouthNowraVehicle = ({ location, pageContext }) => {
  const { vehicle } = pageContext

  const [showModal, setShowModal] = useState(false)

  const dealership = "southnowra"

  return (
    <Layout location={location}>
      <>
        <StockCarsForm
          show={showModal}
          setShow={setShowModal}
          vehicle={vehicle}
          dealership={dealership}
        />
        <SEO
          title={`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
          description={`${vehicle.sellingPoints}`}
          image={
            vehicle.images &&
            `${fileBucket}/images/southnowra/${vehicle.stockNumber}_1_1000.jpg`
          }
        />
        <Heading title={`${vehicle.year} ${vehicle.make} ${vehicle.model}`} />
        {vehicle.derivative && (
          <SubHeading subHeading={`${vehicle.derivative}`} />
        )}
        <div className="row">
          <div className="col-12 col-md-6">
            <ImageGallery images={vehicle.images} dealership={dealership} />
          </div>
          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-12 text-center">
                <p className="vehicle-price">{`$${vehicle.egcPrice.toLocaleString()}`}</p>
              </div>
            </div>
            <div className="row vehicle-description-item-first">
              <div className="col-4">
                <span>
                  <b>Colour:</b>
                </span>
              </div>
              <div className="col-8">
                <span>{vehicle.colour}</span>
              </div>
            </div>
            <div className="row vehicle-description-item">
              <div className="col-4">
                <span>
                  <b>Body:</b>
                </span>
              </div>
              <div className="col-8">
                <span>{vehicle.body}</span>
              </div>
            </div>
            <div className="row vehicle-description-item">
              <div className="col-4">
                <span>
                  <b>Transmission:</b>
                </span>
              </div>
              <div className="col-8">
                <span>{vehicle.transmission}</span>
              </div>
            </div>
            <div className="row vehicle-description-item">
              <div className="col-4">
                <span>
                  <b>Engine Size:</b>
                </span>
              </div>
              <div className="col-8">
                <span>{vehicle.engine}</span>
              </div>
            </div>
            <div className="row vehicle-description-item">
              <div className="col-4">
                <span>
                  <b>Fuel Type:</b>
                </span>
              </div>
              <div className="col-8">
                <span>{vehicle.fuel}</span>
              </div>
            </div>
            <div className="row vehicle-description-item">
              <div className="col-4">
                <span>
                  <b>Kilometres:</b>
                </span>
              </div>
              <div className="col-8">
                <span>{vehicle.kilometres.toLocaleString()}</span>
              </div>
            </div>
            {vehicle.series && (
              <div className="row vehicle-description-item">
                <div className="col-4">
                  <span>
                    <b>Series:</b>
                  </span>
                </div>
                <div className="col-8">
                  <span>{vehicle.series}</span>
                </div>
              </div>
            )}
            <div className="row vehicle-description-item">
              <div className="col-4">
                <span>
                  <b>Registration:</b>
                </span>
              </div>
              <div className="col-8">
                <span>{vehicle.rego ? vehicle.rego : "TBC"}</span>
              </div>
            </div>
            <div className="row vehicle-description-item">
              <div className="col-4">
                <span>
                  <b>Stock Number:</b>
                </span>
              </div>
              <div className="col-8">
                <span>{vehicle.stockNumber}</span>
              </div>
            </div>
            <div className="row vehicle-description-item-last">
              <div className="col-4">
                <span>
                  <b>VIN:</b>
                </span>
              </div>
              <div className="col-8">
                <span>{vehicle.vin}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center">
                <p className="detail">Vehicle Description:</p>
                <p>{vehicle.sellingPoints}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 buttons-container">
                <ul className="nav justify-content-center">
                  <li className="nav-item mx-2 mb-3">
                    <Button
                      variant="primary"
                      bsPrefix="smg"
                      onClick={() => setShowModal(true)}
                    >
                      Send Enquiry <Icon prefix="fas" name="paper-plane" />
                    </Button>
                  </li>
                  <li className="nav-item mx-2 mb-3">
                    <a href="tel:0244230222">
                      <Button variant="primary" bsPrefix="smg">
                        Phone Us <Icon prefix="fas" name="phone" />
                      </Button>
                    </a>
                  </li>
                  <li className="nav-item mx-2 mb-3">
                    <a
                      href="https://www.google.com/maps/place/Solomon+Motor+Group/@-34.8910658,150.5873489,14z/data=!4m8!1m2!2m1!1ssolomon+motors+city!3m4!1s0x6ad661b6ef2d6dbf:0x33917e13647bf0c8!8m2!3d-34.9088274!4d150.6035471"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button variant="primary" bsPrefix="smg">
                        Get Directions{" "}
                        <Icon prefix="fas" name="map-marker-alt" />
                      </Button>
                    </a>
                  </li>
                  <li className="nav-item mx-2 mb-3">
                    <a
                      href={`/used-vehicles/solomon-motors-city/brochure/${vehicle.stockNumber}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button variant="primary" bsPrefix="smg">
                        Print Brochure <Icon prefix="fas" name="print" />
                      </Button>
                    </a>
                  </li>
                  <li className="nav-item mx-2 mb-3">
                    <a
                      href={`mailto:?subject=I found a car you may be interested in&body=Link: ${location.href}`}
                    >
                      <Button variant="primary" bsPrefix="smg">
                        Email to Friend <Icon prefix="fas" name="envelope" />
                      </Button>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-6 text-center">
                <p className="detail">Phone:</p>
                <p className="detail-value">(02) 4423 0222</p>
              </div>
              <div className="col-6 text-center">
                <p className="detail">Location:</p>
                <p className="detail-value">
                  Cnr Princes Hwy &amp; Quinns Lane, South Nowra
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 vehicle-selling-points-container">
            <p className="vehicle-selling-points-title">Vehicle Features:</p>
            <div className="row">{buildOptionsList(vehicle.options)}</div>
          </div>
        </div>
      </>
    </Layout>
  )
}

const buildOptionsList = (vehicleOptions) => {
  const vehicleOptionsArray = vehicleOptions.split(", ")
  const vehicleOptionsList = vehicleOptionsArray.map((vehicleOption, index) => (
    <div className="col-12 col-sm-6 col-lg-4 vehicle-option" key={index}>
      <Icon prefix="fas" name="check-square" /> {vehicleOption}
    </div>
  ))
  return vehicleOptionsList
}

export default SouthNowraVehicle
